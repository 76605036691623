import React, { useEffect, useRef } from "react";
import { ReactLenis, useLenis } from "lenis/react";
import Lottie from "lottie-react";
import Pieverse from "./Pieverse.json";
import PieverseMobile from "./PieverseMobile.json";

import "lenis/dist/lenis.css";
import "./App.scss";

// mobile
const isMobile =
  /mobile/i.test(navigator.userAgent) || window.screen.width < 1100;
const animationData = isMobile ? PieverseMobile : Pieverse;

function App() {
  let lottieRef = useRef(null);
  let containerRef = useRef(null);
  const mediaList = [
    {
      icon: require("./assets/img/X.png"),
      url: "https://x.com/pieverse_io",
    },
    // {
    //   icon: require("./assets/img/Github.png"),
    //   url: "",
    // },
    {
      icon: require("./assets/img/Telegram.png"),
      url: "https://t.me/pieverseprotocol",
    },
    {
      icon: require("./assets/img/Medium.png"),
      url: "https://medium.com/@pieverse",
    },
  ];
  const navigationList = [
    {
      title: "Community",
      style: {
        width: "200px",
        flex: "unset",
      },
      navigation: [
        {
          name: "Linktree",
          url: "",
        },
        {
          name: "Discord",
          url: "",
        },
        {
          name: "Telegram",
          url: "https://t.me/pieverse_news",
        },
        {
          name: "Twitter / X",
          url: "https://x.com/pieverse_io",
        },
      ],
    },
    {
      title: "Products",
      navigation: [
        {
          name: "Pieverse Protocol",
          url: "",
        },
        {
          name: "Wealth Management",
          url: "",
        },
        {
          name: "Payment Solutions",
          url: "",
        },
        {
          name: "(Future products and services)",
          url: "",
        },
      ],
    },
    {
      title: "Company",
      navigation: [
        {
          name: "About Us",
          url: "",
        },
        {
          name: "Governance (Coming Soon)",
          url: "",
        },
        {
          name: "Developers",
          url: "",
        },
        {
          name: "Apply for Agency",
          url: "",
        },
        {
          name: "Use cases",
          url: "",
        },
      ],
    },
    {
      title: "Documentation",
      navigation: [
        {
          name: "Whitepaper",
          url: "",
        },
        {
          name: "GitHub",
          url: "",
        },
        {
          name: "Academy",
          url: "",
        },
      ],
    },
  ];

  useEffect(() => {
    if (lottieRef.current) {
      lottieRef.current.pause();
    }
  }, []);

  useLenis(({ scroll }) => {
    if (containerRef.current) {
      const { offsetTop, scrollHeight } = containerRef.current;
      let frame = ((scroll - offsetTop) / scrollHeight) * 123;

      let lottie = lottieRef.current;
      if (lottie) {
        lottie.goToAndStop(frame + 30, true);
        if ((scroll - offsetTop) / scrollHeight > 1) {
          lottie.stop();
        }
      }
    }
  });

  let option = {
    lerp: 0.1, // Controls how smooth the scrolling is
    duration: 1.5, // Slows down or speeds up the scrolling
    smoothTouch: false, // Disable smooth scroll on touch devices
    smooth: true, // Smooth scroll for desktop (obviously)
    easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // https://www.desmos.com/calculator/brs54l4xou
    direction: "vertical", // vertical, horizontal
    gestureDirection: "vertical", // vertical, horizontal, both
    mouseMultiplier: 1,
    touchMultiplier: 2,
    infinite: false,
  };

  return (
    <ReactLenis root options={option}>
      <div className={isMobile ? "App App-mobile" : "App"}>
        <header className="App-header">
          <div className="App-header_border">
            <img
              src={require("./assets/img/Logo.png")}
              className="App-logo"
              alt="logo"
            />
            {/* <a className="App-login" href="javascript:void(0);" target="_blank" rel="noopener noreferrer">LOGIN</a> */}
          </div>
        </header>
        <div className="copyright-main">
          <span>2024 PIEVERSE.  ALL RIGHTS RESERVED.</span>
          <div>
            {mediaList.map((item, index) => (
              <a href={item.url} target="_blank" rel="noopener noreferrer">
                <img key={index} src={item.icon} alt="" />
              </a>
            ))}
          </div>
        </div>
        <div className="welcome-main">
          <div className="wm-speech">
            <p>
              Pieverse: Empower Community,
              <br /> Redefine Commerce.
            </p>
            <p>
              A decentralized platform merging Web3 and real-world experiences
              through community, loyalty, and payments.
            </p>
          </div>
        </div>
        <div className="wm-advantage-box" ref={containerRef}>
          {/* <Animation /> */}
          <Lottie
            animationData={animationData}
            lottieRef={lottieRef}
            style={{
              minHeight: "100vh",
              backgroundColor: "#FFF",
              display: "flex",
              alignItems: "center",
              position: "sticky",
              top: "0",
            }}
          ></Lottie>
        </div>
        <section>
          <div className="onboard-merchants position-sticky">
            {isMobile && (
              <img
                src={require("./assets/img/Onboard_map_mobile.png")}
                alt=""
              />
            )}
            <p>Onboard Merchants with Ease through an Immersive Gamified Map</p>
            <p>
              Seamlessly connect millions of Web3 users with merchants using our
              gamified map platform.
            </p>
          </div>
          <div className="unlock-rewards position-sticky">
            {isMobile && (
              <img
                src={require("./assets/img/unlock-rewards_bg_mobile.png")}
                alt=""
              />
            )}
            <div>
              <p>Unlock Rewards by Onboarding Merchants</p>
              <p>Join Pieverse's Movement and Earn Big as You Live Smarter</p>
            </div>
          </div>
          <div className="unlock-exchange position-sticky">
            <p>Earn and Exchange Exclusive Coupons and Vouchers</p>
            <p>
              Maximize Savings with Pieverse’s Community-Driven Coupon Exchange
              Platform.
            </p>
            {isMobile ? (
              <div className="unlock-exchange_vouchers-mobile">
                <img src={require("./assets/img/Vouchers_bg.png")} alt="" />
              </div>
            ) : (
              <div className="unlock-exchange_vouchers-img">
                <img src={require("./assets/img/Vouchers_left.png")} alt="" />
                <img src={require("./assets/img/Vouchers_right.png")} alt="" />
              </div>
            )}
          </div>
          <div className="unlock-exclusive position-sticky">
            <p className="first-title text-center">
              Access Exclusive Discounts and Offers
            </p>
            <p className="second-title text-center">
              Power of Community Meets Coupon Exchange
            </p>
            <div className="unlock-exclusive_banner1">
              <div className="ue_banner1_item">
                <p className="first-title">Collect Stamps Earn Rewards</p>
                <p className="second-title">
                  Gamify Loyalty and Turn Purchases into NFTs
                </p>
                <div className="ue_banner1_item_img">
                  <img
                    src={
                      isMobile
                        ? require("./assets/img/Collect_mobile.png")
                        : require("./assets/img/Collect.png")
                    }
                    alt=""
                  />
                </div>
              </div>
              <div className="ue_banner1_item">
                <p className="first-title">Customize Your NFT Passes</p>
                <p className="second-title">
                  Gamify your loyalty experience by turning purchases into
                  customizable NFTs
                </p>
                <div className="ue_banner1_item_img">
                  <img
                    src={
                      isMobile
                        ? require("./assets/img/Generate_mobile.png")
                        : require("./assets/img/Generate.png")
                    }
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="discounts-offers">
              <div className="discounts-offers_item">
                <p className="first-title">
                  A Gamified Loyalty System Rewarding Participation
                </p>
                <p className="second-title">
                  Engage with Merchant and Try Your Luck to Get More Savings
                </p>
              </div>
              <div className="discounts-offers_item">
                <img
                  src={
                    isMobile
                      ? require("./assets/img/DiscountsAndOffers_mobile.png")
                      : require("./assets/img/DiscountsAndOffers.png")
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <footer className="position-sticky" style={{display: "flex", justifyContent: "end"}}>
          <div className="footer-container">
            <div className="footer-logo">
              <img src={require("./assets/img/logo_white.png")} alt="logo" />
              {!isMobile && (
                <div className="footer-copyright">
                  <p>© 2024 Pieverse All rights reserved.</p>
                  <div>
                    {/* <span>Term of Service</span> */}
                    {/* <span>Privacy Policy</span> */}
                  </div>
                </div>
              )}
            </div>
            {/* {navigationList.map((item, index) => (
              <div
                key={index}
                style={{ ...item.style }}
                className="footer-navigation"
              >
                <ul>
                  <li className="fn_li-title">{item.title}</li>
                  {item.navigation.map((i, key) => (
                    <li key={key}>{i.name}</li>
                  ))}
                </ul>
              </div>
            ))} */}
            {isMobile && (
              <div className="footer-copyright">
                <p>© 2024 Pieverse All rights reserved.</p>
                <div>
                  <span>Term of Service</span>
                  <span>Privacy Policy</span>
                </div>
              </div>
            )}
          </div>
        </footer>
      </div>
    </ReactLenis>
  );
}

export default App;
